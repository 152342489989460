import { replace } from 'connected-react-router'
import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { CreateFlightItemViewModel } from './models'
import { createFlightPayloadFromViewModel } from './utils'
import { formOptions } from '../Common/Fields/config'
import FlightFields from '../Common/FlightFields'

import {
  addToTripSaveErrorEvent,
  addToTripSaveEvent,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useCreateTripItem } from 'tripPlanner/hooks/api'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import { TripItem } from 'tripPlanner/types/tripItem'
import { itemSectionId } from 'tripPlanner/utils/itemSection'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'

interface Props {
  tripId: string
  defaultDate?: string
  initialData?: Partial<CreateFlightItemViewModel>
}

function CreateFlight(props: Props) {
  const { tripId, defaultDate, initialData } = props

  const { closeModal, goBack } = useModalCallbacks()

  const methods = useForm<CreateFlightItemViewModel>({
    ...formOptions,
    defaultValues: {
      type: 'FLIGHT',
      sourceType: 'NA',
      startDate: initialData?.startDate ?? defaultDate,
      endDate: initialData?.endDate ?? defaultDate,
      startPlace: {
        ...initialData?.startPlace,
      },
      endPlace: {
        ...initialData?.endPlace,
      },
      flightNumber: initialData?.flightNumber,
      airlineName: initialData?.airlineName,
      destinationAirportCode: initialData?.destinationAirportCode,
      originAirportCode: initialData?.originAirportCode,
      isEndDateSame: initialData?.isEndDateSame,
      confirmationCode: initialData?.confirmationCode,
      isBooked: !!initialData?.confirmationCode,
    },
  })
  const { handleSubmit } = methods
  const dispatch = useAppDispatch()

  const onInvalid = useCallback(() => {
    fireInteractionEvent(addToTripSaveErrorEvent('flights_custom_manual'))
  }, [])

  const { mutate: createMutate, isLoading } = useCreateTripItem({
    onSuccess: ({ id }: TripItem) => {
      fireInteractionEvent(addToTripSaveEvent('flights_custom_manual'))
      dispatch(replace({ hash: itemSectionId(id) }))
      closeModal()
    },
    onError: onInvalid,
  })

  const createFlightItem = useCallback(
    (viewModel: CreateFlightItemViewModel) => {
      createMutate({
        tripId,
        tripItem: createFlightPayloadFromViewModel(viewModel),
      })
    },
    [createMutate, tripId],
  )

  return (
    <>
      <ModalHeader
        title="Add flight manually"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <FormProvider {...methods}>
        <ModalBody>
          <ModalContent>
            <form noValidate>
              <FlightFields tripId={tripId} />
            </form>
          </ModalContent>
        </ModalBody>

        <ModalFooter
          primaryActionProps={{
            onClick: handleSubmit(createFlightItem, onInvalid),
            children: 'Save',
            disabled: isLoading,
          }}
        />
      </FormProvider>

      {isLoading && <LoadingIndicator floating />}
    </>
  )
}

export default CreateFlight
